import { Nullable } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@mantine/core';
import { CircularityOfMaterials } from './circularity-of-materials';
import { ClimateImpactsOfMaterials } from './climate-impacts-of-materials';
import { EcosystemImpactsOfMaterials } from './ecosystem-impacts-of-materials';

export const MaterialInsights = () => {
    const { t } = useTranslation();

    const tabs = [t('climateImpactsOfMaterials'), t('circularityOfMaterials'), t('ecosystemImpactsOfMaterials')];

    // Set the default active tab to "Climate Impacts of Materials"
    const [activeTab, setActiveTab] = useState<Nullable<string>>(tabs[0]);

    return (
        <Tabs value={activeTab} onChange={setActiveTab}>
            <Tabs.List mb="lg">
                {tabs.map((tab) => (
                    <Tabs.Tab key={tab} value={tab}>
                        {tab}
                    </Tabs.Tab>
                ))}
            </Tabs.List>

            <Tabs.Panel value={t('climateImpactsOfMaterials')}>
                <ClimateImpactsOfMaterials />
            </Tabs.Panel>
            <Tabs.Panel value={t('circularityOfMaterials')}>
                <CircularityOfMaterials />
            </Tabs.Panel>
            <Tabs.Panel value={t('ecosystemImpactsOfMaterials')}>
                <EcosystemImpactsOfMaterials />
            </Tabs.Panel>
        </Tabs>
    );
};
